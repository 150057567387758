import React, { useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { saveStepTwoData, registerUser } from '../../services/cadastroOnboarding/cadastroService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const StepTwo = ({ onBack }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [cpf, setCpf] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const validateAndRegister = async () => {
        if (!password || !confirmPassword || !cpf || !dataNascimento) {
            setError('Preencha todos os campos!');
            return;
        }
        if (password !== confirmPassword) {
            setError('As senhas não coincidem!');
            return;
        }
        if (password.length < 6) {
            setError('A senha deve ter pelo menos 6 caracteres!');
            return;
        }

        const dataNascimentoRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dataNascimentoRegex.test(dataNascimento)) {
            setError('Data de nascimento inválida! Use o formato YYYY-MM-DD.');
            return;
        }

        setError('');

        try {
            saveStepTwoData(password, confirmPassword, cpf, dataNascimento);
            await registerUser();
            toast.success('Cadastro realizado com sucesso!', { autoClose: 3000 });
            navigate('/login');
        } catch (error) {
            toast.error(error.message || 'Erro ao realizar cadastro');
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            validateAndRegister();
        }
    };

    return (
        <div className="onboarding-step">
            <h1 className="title">Defina sua senha</h1>
            {error && <p className="error-message" role="alert">{error}</p>}
            <div className="form-group">
                <label htmlFor="cpf">CPF:</label>
                <input
                    type="text"
                    id="cpf"
                    value={cpf}
                    onChange={(e) => setCpf(e.target.value)}
                    placeholder="000.000.000-00"
                    aria-label="Digite seu CPF"
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="dataNascimento">Data de Nascimento:</label>
                <input
                    type="date"
                    id="dataNascimento"
                    value={dataNascimento}
                    onChange={(e) => setDataNascimento(e.target.value)}
                    aria-label="Digite sua data de nascimento"
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="password">Senha:</label>
                <input
                    type="password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Crie uma senha"
                    aria-label="Digite sua senha"
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className="form-group">
                <label htmlFor="confirmPassword">Confirme a Senha:</label>
                <input
                    type="password"
                    id="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirme sua senha"
                    aria-label="Confirme sua senha"
                    onKeyPress={handleKeyPress}
                />
            </div>
            <div className="onboarding-actions">
                <button className="onboarding-back-button" onClick={onBack} aria-label="Voltar">
                    Voltar
                </button>
                <button className="onboarding-next-button" onClick={validateAndRegister} aria-label="Registrar">
                    Registrar <FaArrowRight />
                </button>
            </div>
            <ToastContainer position="top-right" autoClose={3000} hideProgressBar closeOnClick pauseOnHover />
        </div>
    );
};

export default StepTwo;

import React, { useState } from 'react';
import { FaArrowRight, FaTimes } from 'react-icons/fa';
import { Link } from 'gatsby';
import { validateStepOne, saveStepOneData } from '../../services/cadastroOnboarding/cadastroService';

const StepOne = ({ onNext }) => {
    const [nome, setNome] = useState('');
    const [telefone, setTelefone] = useState('');
    const [email, setEmail] = useState('');
    const [nomeClinica, setNomeClinica] = useState('');
    const [error, setError] = useState('');

    const validateAndNext = () => {
        const formData = { nome, email, telefone, nomeClinica };

        const error = validateStepOne(formData);
        if (error) {
            setError(error);
            return;
        }

        setError('');

        saveStepOneData(formData);

        onNext();
    };

    return (
        <div className="onboarding-step">
            <Link to="/" className="close-button" aria-label="Fechar">
                <FaTimes />
            </Link>
            <h1 className="title">Crie sua conta</h1>
            {error && <p className="error-message" role="alert">{error}</p>}
            <div className="form-group">
                <label htmlFor="nome">Nome:</label>
                <input
                    type="text"
                    id="nome"
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                    placeholder="Digite seu nome completo"
                />
            </div>
            <div className="form-group">
                <label htmlFor="telefone">Telefone:</label>
                <input
                    type="text"
                    id="telefone"
                    value={telefone}
                    onChange={(e) => setTelefone(e.target.value)}
                    placeholder="Digite seu telefone"
                />
            </div>
            <div className="form-group">
                <label htmlFor="email">E-mail:</label>
                <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Digite seu e-mail"
                />
            </div>
            <div className="form-group">
                <label htmlFor="nomeClinica">Nome da Clínica/Consultório:</label>
                <input
                    type="text"
                    id="nomeClinica"
                    value={nomeClinica}
                    onChange={(e) => setNomeClinica(e.target.value)}
                    placeholder="Digite o nome da sua clínica ou consultório"
                />
            </div>
            <button
                className="onboarding-next-button"
                onClick={validateAndNext}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') validateAndNext();
                }}
                aria-label="Próximo passo"
            >
                Próximo <FaArrowRight />
            </button>
        </div>
    );
};

export default StepOne;

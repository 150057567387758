import React, { useState } from 'react';
import Modal from '../components/ui-components/Modal/Modal';
import StepOne from '../components/StepOne/StepOne';
import StepTwo from '../components/StepTwo/StepTwo';
import FinalStep from '../components/FinalStep/FinalStep';
import './cadastro-de-novo-usuario.css';
import Seo from '../components/Seo';

const OnboardingProcess = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const handleBackStep = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleNextStep = () => {
        if (currentStep < 3) {
            setCurrentStep(currentStep + 1);
        } else {
            setIsModalOpen(false);
        }
    };

    return (
        <>
            <Seo title="Cadastre-se | Prontuário Fácil" description="Crie sua conta gratuita no Prontuário Fácil e comece a gerenciar seus prontuários eletronicamente agora mesmo." />
            {isModalOpen && (
                <Modal>
                    {currentStep === 0 && <StepOne onNext={handleNextStep} />}
                    {currentStep === 1 && <StepTwo onBack={handleBackStep} onNext={handleNextStep} />}
                    {currentStep === 2 && <FinalStep onNext={handleNextStep} />}
                </Modal>
            )}
        </>
    );
};

export default OnboardingProcess;

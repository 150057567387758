import React from 'react';

const FinalStep = ({ onStartUsing }) => {
    return (
        <div className="onboarding-step final-step" aria-labelledby="final-step-title" aria-describedby="final-step-description">
            <h2 id="final-step-title">Cadastro Concluído!</h2>
            <p id="final-step-description">Seu cadastro foi concluído com sucesso. Agora você pode começar a explorar todas as funcionalidades disponíveis.</p>
            <button 
                className="start-using-button" 
                onClick={onStartUsing}
                aria-label="Começar a usar o Prontuário Fácil"
            >
                Começar a Usar
            </button>
        </div>
    );
};

export default FinalStep;

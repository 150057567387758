import { toast } from 'react-toastify';
import axios from 'axios';

const validateStepOne = (formData) => {
  if (!formData.nome || !formData.email || !formData.telefone || !formData.nomeClinica) {
    return 'Todos os campos são obrigatórios!';
  }
  return null;
};

const saveStepOneData = (formData) => {
  localStorage.setItem('cadastroUsuarioStep1', JSON.stringify(formData));
};

const saveStepTwoData = (password, confirmSenha, cpf, dataNascimento) => {
  const step1Data = JSON.parse(localStorage.getItem('cadastroUsuarioStep1'));
  const dadosCadastro = { ...step1Data, senha: password, confirmSenha: confirmSenha, cpf: cpf, dataNascimento: dataNascimento };
  localStorage.setItem('cadastroUsuarioStep2', JSON.stringify(dadosCadastro));
};

function validateUserData(userData) {
  if (!userData.nome || !userData.email || !userData.senha || !userData.confirmSenha || !userData.dataNascimento || !userData.telefone || !userData.nomeClinica || !userData.cpf) {
    return false;
  }

  const dataNascimentoRegex = /^\d{4}-\d{2}-\d{2}$/;
  if (!dataNascimentoRegex.test(userData.dataNascimento)) {
    return false;
  }

  return true;
}

const registerUser = async () => {
  try {
    const userData = JSON.parse(localStorage.getItem('cadastroUsuarioStep2'));

    // Validação dos dados
    if (!validateUserData(userData)) {
      throw new Error('Dados de cadastro inválidos. Verifique todos os campos e o formato da data de nascimento (YYYY-MM-DD).');
    }

    console.log('Dados da Requisição:', userData);

    const response = await axios.post(`${process.env.REACT_APP_API_URL}/usuarios`, {
      nome: userData.nome,
      email: userData.email,
      senha: userData.senha,
      confirmSenha: userData.confirmSenha,
      cpf: userData.cpf, 
      dataNascimento: userData.dataNascimento,
      telefone: userData.telefone,
      nomeClinica: userData.nomeClinica,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 201) {
      localStorage.removeItem('cadastroUsuarioStep1');
      localStorage.removeItem('cadastroUsuarioStep2');
      console.log('Usuário cadastrado com sucesso!');

      // Redirecionar o usuário ou exibir mensagem de sucesso, se necessário
    } else {
      throw new Error(response.data.error || 'Erro desconhecido');
    }
  } catch (error) {
    console.error('Erro na requisição:', error);

    if (error.response && error.response.status === 400) {
      toast.error(error.response.data.error || 'Erro de solicitação. Verifique os dados enviados.');
    } else if (error.response && error.response.status === 500) {
      toast.error('Erro interno do servidor. Tente novamente mais tarde.');
    } else {
      toast.error(error.message);
    }
  }
};

export { validateStepOne, saveStepOneData, saveStepTwoData, registerUser };
